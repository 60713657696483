import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientHelper} from '../app.httpclient';
import {HttpClient} from '@angular/common/http';
import 'rxjs';
import {AuthenticationHelper} from '../app.authentication';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ContactService {
  private httpClient;
  private prefix: string = 'api/product/' + this.authentication.getSelectedObject() + '/';
  private contact = 'contact';
  private contactGroup = 'contactgroup';
  private contactDetails = 'api/contact/info';
  private contactVersionOnePrefix = 'api/contact/v1/';
  private contactDashboard = 'api/dashboard/v1/';
  private contactWidget = 'api/widget/v1/'; // old url
  private participantWidget = 'api/widget/v2/'; // new url
  private product = 'product/' + this.authentication.getSelectedObject();

  constructor(httpClient: HttpClient, private httpHelper: HttpClientHelper, private authentication: AuthenticationHelper) {
    this.httpClient = httpClient;
  }

  getPersonRelationships(personId) {
    const url = this.contactVersionOnePrefix + 'relationships/' + personId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  addPersonRelationship(data: any) {
    const url = this.contactVersionOnePrefix + 'relationships/create';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletePersonRelationship(id) {
    const url = this.contactVersionOnePrefix + 'relationships/' + id;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createContactGroup(data) {
    const url = this.prefix + this.contactGroup;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateContactGroup(data) {
    const url = this.prefix + this.contactGroup;
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteContactGroup(groupId) {
    const url = this.prefix + this.contactGroup + '/' + groupId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactGroupForProduct() {
    const url = this.prefix + this.contactGroup;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  cloneContactGroup(data, groupId): Observable<any> {
    const url = this.prefix + this.contactGroup + '/' + groupId + '/clone';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportContactGroup(groupId): Observable<any> {
    const url = this.prefix + this.contactGroup + '/' + groupId + '/export';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  createContact(data) {
    const url = 'api/v2/product/' + this.authentication.getSelectedObject() + '/contact';
    data.businessUnitId = this.authentication.getSelectedObject();
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  createContactFromPersonSearch(data, sponsor) {
    const url = 'api/v2/product/' + this.authentication.getSelectedObject() + '/addFromSearch/' + sponsor;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  resendParticipantActivation(personId) {
    const url = 'api/v2/contact/reactivate/' + personId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  setParticipantStatus(data) {
    const url = 'api/v2/contact/setStatus'
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonStatus(personId) {
    const url = 'api/v2/contact/getStatus/' + personId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Return list of participants in the participant groups for a given participant and service group.
   * @param participantId
   * @param serviceGroupId
   */
  getCoparticipants(participantId, serviceGroupId) {
    const url = 'api/v2/contact/' + participantId + '/serviceGroup/' + serviceGroupId + '/coparticipants';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSingleContact(data) {
    const url = this.prefix + this.contact;
    data.businessUnitId = this.authentication.getSelectedObject();
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllContactWithRespectToGroup(groupId): Observable<any> {
    const url = this.prefix + this.contactGroup + '/' + groupId + '/' + this.contact;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  searchContact(userName): Observable<any> {
    const url = 'api/' + this.contact + '/search' + '/' + userName;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllContacts(data: any = ''): Observable<any> {
    if (!data) {
      data = {
        length: 0
      }
    }
    const url = this.contactVersionOnePrefix + this.product + '/business/list';

    // To be removed after applying pagination in all places of API call
    if (!data.pageNumber) {
      data.pageNumber = 1;
      data.pageSize = 100000;
    }

    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTeamMemberDashboardData(): Observable<any> {
      const url = this.contactVersionOnePrefix + this.product + '/teamDashboard';
      return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTeamMemberParticipantListPaged(params): Observable<any> {
    const url = this.contactVersionOnePrefix + this.product + '/teamDashboard/paginated';
    return this.httpHelper.post(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * create widget for the team member dashboard
   * @param data
   */
  createTeamMemberDashboardWidget(data) {
    const url = this.participantWidget + 'teamDashboard/create';
    return this.httpHelper.post(url, data).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * save changes to a team member dashboard
   * @param teamMemberId
   * @param data
   */
  saveTeamMemberDashboardWidgets(data) {
    const url = this.participantWidget + 'teamDashboard/save/' + this.authentication.getSelectedObject();
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteTeamMemberDashboardWidget(widgetId) {
    const url = this.participantWidget + 'teamDashboard/delete/' + widgetId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactDetails(contactId): Observable<any> {
    const url = this.contactVersionOnePrefix + this.product + '/business/contact/' + contactId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteContactFromGroup(contactId, groupId) {
    const url = this.prefix + this.contactGroup + '/' + groupId + '/' + this.contact + '/' + contactId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteContactFromSystem(contactId) {
    const url = 'api/contact/' + contactId;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateContactRiskLevel(data) {
    const url = this.contactVersionOnePrefix + 'riskLevel/add';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateContactEngagementLevel(data) {
    const url = this.contactVersionOnePrefix + 'engagementLevel/add';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateContactSatisfactionLevel(data) {
    const url = this.contactVersionOnePrefix + 'satisfactionLevel/add';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSingleContactActivities(data): Observable<any> {
    const url = this.contactVersionOnePrefix + 'product/' + this.authentication.getSelectedObject() + '/business/activity';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactRiskLevelLineChartData(contactId) {
    const url = this.contactVersionOnePrefix + 'riskLevel/contact/' + contactId + '/product/' + this.authentication.getSelectedObject();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactEngagementLevelLineChartData(contactId) {
    const url = this.contactVersionOnePrefix + 'engagementLevel/contact/' + contactId + '/product/' + this.authentication.getSelectedObject();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContactSatisfactionLevelLineChartData(contactId) {
    const url = this.contactVersionOnePrefix + 'satisfactionLevel/contact/' + contactId + '/product/' + this.authentication.getSelectedObject();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getThresholdIndicatorList() {
    const url = this.prefix + 'indicators';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateThresholdIndicatorList(data) {
    const url = this.prefix + 'indicator';
    return this.httpHelper.put(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTeamMemberDashboardWidgets() {
    const url = this.contactDashboard + 'actioncenter/' + this.authentication.getSelectedObject();
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getParticipantDashboardWidgets(personId) {
    const url = this.contactDashboard + this.product + '/participant/' + personId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  copyParticipantDashboardFrom(sourcePersonId, destPersonId) {
    const url = this.contactDashboard + this.product + '/copyFrom/' + sourcePersonId + '/to/' + destPersonId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSystemWidgets() {
    const url = this.contactDashboard + this.product + '/system';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllWidgets() {
    const url = this.contactDashboard + this.product;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveContactDashboardWidgets(contactId, data) {
    const url = this.contactDashboard + this.product + '/participant/' + contactId;
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  createWidget(data) {
    const url = this.participantWidget + 'create';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateWidget(data) {
    const url = this.participantWidget + 'update';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteWidget(id) {
    const url = this.participantWidget + 'delete/' + id;
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getWidgetData(data) {
    const url = this.participantWidget + this.product + '?widget=' + data.widgetId + '&contact=' + data.contactId + '&category=' + data.category + '&subCategory=' + data.subCategory + '&type=' + data.type + '&fromTs=' + data.fromTs + '&toTs=' + data.toTs;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getWidgetDetails(widgetId) {
    const url = this.participantWidget + 'details/' + widgetId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getMatrixWidgetAttributes(widgetId) {
    const url = this.participantWidget + 'matrixWidget/attributes/' + widgetId;
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportMatrixWidgetAttributeValues(widgetId) {
    const url = this.httpHelper.getApiBaseUrl() + this.participantWidget + 'matrixWidget/export/' + widgetId;
    return this.httpClient.get(url, {responseType: 'blob'})
      .pipe(map(this.exportAttributeMatrixData))
      .pipe(catchError(this.handleError));
  }

  exportAttributeMatrixData(res) {
    return res;
  }

  cloneWidget(data) {
    const url = this.participantWidget + 'clone';
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getParticipantBusinessAccounts() {
    const url = this.prefix + this.contactGroup + '/accounts';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  extractResponse(res: JSON) {
    return res;
  }

  handleError(error: any) {
    return throwError(error);
  }


}
